/*
 * @Author       : your name
 * @Date         : 2020-11-25 15:48:34
 * @LastEditTime : 2020-12-02 18:32:54
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/api/home.js
 */
import request from '@/helper/request'
// import { get } from 'core-js/fn/dict'

export function getLifeAndFamousSnap(data) {
  return request({
    url: 'api/life-famous-snap',
    method: 'post',
    data: data
  })
}
export function getCommonPageData(params) {
  return request({
    url: 'api/pages',
    method: 'get',
    params: params
  })
}
export function getMemberListData(data) {
  return request({
    url: 'api/member-and-elite-list',
    method: 'post',
    data: data
  })
}
export function getSummitList(params) {
  return request({
    url: 'api/summit-list',
    method: 'get',
    params: params
  })
}
export function getSummitMember(data) {
  return request({
    url: 'api/summit-member-list',
    method: 'post',
    data: data
  })
}
export function getFamousDetail(data) {
  return request({
    url: 'api/life-famous-detail',
    method: 'post',
    data: data
  })
}
export function getComponyList(data) {
  return request({
    url: 'api/member-and-elite-list-company',
    method: 'post',
    data: data
  })
}